<script>
export default {
	props: ['cooperate'],
};
</script>

<template>
	<div class="rounded-xl text-left  shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark  px-3 py-3">
		<div class="text-left font-general-medium text-lg text-indigo-500  mb-2">
			{{ cooperate.question }}
		</div>

		<p class="text-lg text-left text-gray-400 block"
				v-for="word in cooperate.content"
				:key="word">
			{{ word }}
		</p>
	</div>
</template>

<style lang="scss" scoped></style>
