<script>
import feather from 'feather-icons';
import CooperateSingle from './CooperatesSingle.vue';
import cooperates from '../../data/cooperates';

export default {
	components: { CooperateSingle },
	data: () => {
		return {
			cooperates,
			cooperatesHeading: 'UP主合作',
			selectedCategory: '',
			searchCooperate: ''
		};
	},
	computed: {
		// Get the filtered cooperates
		filteredCooperates() {
			if (this.selectedCategory) {
				return this.filterCooperatesByCategory();
			} else if (this.searchCooperate) {
				return this.filterCooperatesBySearch();
			}
			return this.cooperates;
		},
	},
	methods: {
		// Filter cooperates by category
		filterCooperatesByCategory() {
			return this.cooperates.filter((item) => {
				let category =
					item.category.charAt(0).toUpperCase() +
					item.category.slice(1);
				console.log(category);
				return category.includes(this.selectedCategory);
			});
		},
		// Filter cooperates by title search
		filterCooperatesBySearch() {
			let cooperate = new RegExp(this.searchCooperate, 'i');
			return this.cooperates.filter((el) => el.title.match(cooperate));
		}
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<!-- Cooperates grid -->
	<section class="pt-16 sm:pt-14">
		<!-- Cooperates grid title -->
		<div class="text-center">
			<p
				class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light"
			>
				{{ cooperatesHeading }}
			</p>
		</div>

		<!-- Cooperates grid -->
		<div
			class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 mt-6 sm:gap-10"
		>
			<CooperateSingle
				v-for="cooperate in filteredCooperates"
				:key="cooperate.id"
				:cooperate="cooperate"
			/>
		</div>
	</section>
	
</template>

<style scoped></style>
<!-- filteredProjects -->