<script>
import feather from 'feather-icons';
import CommentSingle from './CommentsSingle.vue';
import comments from '../../data/comments';

export default {
	components: { CommentSingle },
	data: () => {
		return {
			comments,
			commentsHeading: '用户评价',
			selectedCategory: '',
			searchComment: '',
			page: 1
		};
	},
	computed: {
		// Get the filtered comments
		filteredComments() {
			if (this.selectedCategory) {
				return this.filterCommentsByCategory();
			} else if (this.searchComment) {
				return this.filterCommentsBySearch();
			}
			return this.page == -1 ? this.comments : this.comments.slice(0, this.page * 6);
		},
	},
	methods: {
		// Filter comments by category
		filterCommentsByCategory() {
			return this.comments.filter((item) => {
				let category =
					item.category.charAt(0).toUpperCase() +
					item.category.slice(1);
				console.log(category);
				return category.includes(this.selectedCategory);
			});
		},
		// Filter comments by title search
		filterCommentsBySearch() {
			let comment = new RegExp(this.searchComment, 'i');
			return this.comments.filter((el) => el.title.match(comment));
		},
		get_more_comment() {
			// const start = this.page * 6;
			// const remainingComments = this.comments.slice(start);
			// if (remainingComments.length > 6) {
			// 	const nextComments = remainingComments.slice(0, 6);
			// 	this.show_comments = [...this.show_comments, ...nextComments];
			// } else if (remainingComments.length > 0) {
			// 	// Load the remaining comments
			// 	this.show_comments = [...this.show_comments, ...remainingComments];
			// } 
			this.page = (this.page + 1) * 6 >= this.comments.length ? -1: this.page + 1;
		}
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<!-- Comments grid -->
	<section class="pt-16 sm:pt-14">
		<!-- Comments grid title -->
		<div class="text-center">
			<p
				class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light"
			>
				{{ commentsHeading }}
			</p>
		</div>

		<!-- Comments grid -->
		<div
			class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 mt-6 sm:gap-10"
		>
			<CommentSingle
				v-for="comment in filteredComments"
				:key="comment.id"
				:comment="comment"
			/>
		</div>
		<div v-if="this.page != -1" class="text-center sm:mt-6">
			<span
				@click="get_more_comment"
				class="font-general text-sl mb-4 text-indigo-500 hover:text-indigo-600 focus:ring-1 focus:ring-indigo-900 text-ternary-dark dark:text-indigo-500 cursor-pointer">
		
			更多
			</span>
		</div>
	</section>
	
</template>

<style scoped></style>
<!-- filteredProjects -->