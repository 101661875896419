<script>
export default {
	props: ['project'],
	mounted() {
		setTimeout(() => {
			this.playVideo();
		}, 2000);
	},
	methods: {
		playVideo() {
			// const videoElement = document.getElementById(this.project.tag);
			// if (videoElement) {
			// 	videoElement.play();
			// }
		},
	}
};
</script>

<template>
	<div
		to="/projects/single-project"
		class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
		aria-label="Single Project"
	>
		<div>
			<!-- <video
				:id="project.tag"
				:src="'https://www.fine.today/static/video/' + project.tag + '.MP4'"
				autoplay
				loop
				controls
				class="rounded-t-xl border-none"
				></video> -->
								<!-- controls -->

			<img
			:src="'https://www.fine.today/static/video/' + project.tag + '.gif'"
				:alt="project.title"
				class="rounded-t-xl border-none"
			/>
		</div>
		<div class="text-left px-3 py-3">
			<div class="flex items-center justify-start mb-2"> <!-- Updated class to "items-center" -->
				<img
				@click="playVideo"
				:src="require('@/assets/images/logo/' + project.tag +'.png')"
				:alt="project.title"
				class="rounded border-none w-7 h-7 inline-block mr-2"
				/>
				<span class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold inline-block">{{ project.title }}</span>
			</div>
			<span class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light">{{ project.category }}</span>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
