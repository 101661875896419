// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const comments = [
	{
		id: 1,
		title: '历史年轮',
		tag: 'history',
		nickname: '北冰洋回南天',
		category: '历史的断代史会产生一种跨越时代的错觉，但实际上以时间推算会发现很多事情与印象不符，譬如嬴政大不了刘邦几岁，他们都是列国时代的人。然而断代会让这一切混淆起来，直到很久以后有人分析期间文化才发现这条有趣的线索。那么期待这个软件能让人发现两个人物间不同年岁的交际，年老的垂暮有可能碰上年少的朝气，这也许不失为一种历史的浪漫。',
	},
	{
		id: 1,
		title: '历史年轮',
		tag: 'history',
		nickname: 'qingyuaner',
		category: '太牛了，这就是我要找的产品，昨天晚上看了B站up东兴苟十三的视频，就在想如果有人能把历史人物之间的关系，人物生平与作品的关系能够索引排布好，那该是多么有意义的事情，结果一觉醒来，就在即刻上看到历史年轮，太棒了，一定要支持！',
	},
	{
		id: 1,
		title: '历史年轮',
		tag: 'worden',
		nickname: 'moonner在路上',
		category: '是我的梦中背单词app了',

	},
	{
		id: 1,
		title: '历史年轮',
		tag: 'worden',
		nickname: 'GPUk',
		category: '好用呀，和其他app差异化挺明显',
	},
	{
		id: 1,
		title: '历史年轮',
		tag: 'history',
		nickname: 'EphraimWang',
		category: '拖动进度条看同时代的巨人们一个个变白下线还挺震撼的',
	},
	{
		id: 1,
		title: '历史年轮',
		tag: 'history',
		nickname: '复一日记',
		category: '新奇的产品，独特的创意，总要想看看。',
	},
	
	{
		id: 1,
		title: '历史年轮',
		tag: 'history',
		nickname: '列文虎克你来了',
		category: '产品真好呀 但是看着身边人物一个个消失 心里不是个滋味',
	},
	{
		id: 1,
		title: '历史年轮',
		tag: 'history',
		nickname: '扮演美麗勤勞的小蜜蜂一角',
		category: '很有趣！！像掉进了兔子洞可以看一天 如果页面能更精致一点就更好了',
	},
	
	{
		id: 1,
		title: '历史年轮',
		tag: 'history',
		nickname: 'carpenterworm',
		category: '编年体哈，研究历史人物，朝代，前后关系，很直观的纵向看了',
	},
	{
		id: 1,
		title: '历史年轮',
		tag: 'history',
		nickname: '时间的蘑菇',
		category: '因吹斯听，最近在看《射雕英雄传》，正好可以跟历史做个比较研究。',
	},
	
];

export default comments;
