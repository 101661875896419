export const socialLinks = [
	{
		id: 3,
		name: 'Twitter',
		icon: 'twitter',
		url: 'https://twitter.com/keweiwu785585',
	},
	{
		id: 4,
		name: 'instagram',
		icon: 'instagram',
		url: 'https://www.instagram.com/finekewei/',
	},
	{
		id: 5,
		name: 'Jike',
		icon: 'globe',
		url: 'https://web.okjike.com/u/D0E8111D-E3B6-4F6A-9EF6-2736F314A3D2',
	}
];
