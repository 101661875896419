// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const cooperates = [
	{
		id: 1,
		tag: 'good',
		question: '合作优势',
		content: [
			'不丢面子：我的产品/内容新颖有质感，是原创乃至独创的；', 
			'充分信任：不审稿、不催稿、追求真实产品体验不虚假；',
			'在意合作体验，追求长期合作，不一锤子买卖(多个APP)；',
			// '省心：APP内每个单词/人物都能一键生成视频卡片。', 
		],
	},
	{
		id: 1,
		tag: 'history',
		question: '合作形式(包含但不限于)',
		content: [
			'按次合作：广告植入，介绍产品使用体验/用法/优势等；', 
			'周期合作： 每月顺手发4-6篇使用笔记；',
			// '置顶留言：在热门英语、历史内容下置顶APP下载信息；', 
			'价格敏感：少有新颖产品，感谢支持创新！'
		],
	}
];

export default cooperates;
