<script>
import AppBanner from '@/components/shared/AppBanner';
import ContactBottom from '@/components/shared/ContactBottom';
import ProjectsGrid from '../components/projects/ProjectsGrid';
import CommentsGrid from '../components/comments/CommentsGrid';
import CooperatesGrid from '../components/cooperates/CooperatesGrid';


// import { Comment } from 'vue';

export default {
	name: 'Home',
	components: {
		AppBanner,
		ContactBottom,
		ProjectsGrid,
		CommentsGrid,
		CooperatesGrid
	}
};
</script>

<template>
	<div class="container mx-auto">
		<!-- Banner -->
		<AppBanner class="mb-5 sm:mb-8" />

		<!-- Projects -->
		<ProjectsGrid />

		<!-- Projects -->

		<CommentsGrid />
		<CooperatesGrid />

		<ContactBottom />
		
	</div>
</template>

<style scoped></style>
