<script>
import ThemeSwitcher from '../ThemeSwitcher';
import feather from 'feather-icons';
import Button from '../reusable/Button.vue';
import HireMeModal from '../HireMeModal.vue';

export default {
	components: {
		ThemeSwitcher,
		HireMeModal,
		Button,
	},
	data() {
		return {
			isOpen: false,
			modal: false,
			theme: ''
		};
	},

	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		this.theme = localStorage.getItem('theme') || 'light';
	},

	methods: {
		updateTheme(theme) {
			this.theme = theme;
		},
		go_to(pageStr) {
			this.isOpen = false;
			this.$router.push(pageStr);
		},
		showModal() {
			this.isOpen = false;
			// 
			if (this.modal) {
				document
					.getElementsByTagName('html')[0]
					.classList.remove('overflow-y-hidden');
				this.modal = false;
			} else {
				console.log(338)
				document
					.getElementsByTagName('html')[0]
					.classList.add('overflow-y-hidden');
				this.modal = true;
			}
		},
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<nav id="nav" class="sm:container sm:mx-auto">
		<!-- Header start -->
		<div
			class="z-10 max-w-screen-lg xl:max-w-screen-xl block sm:flex sm:justify-between sm:items-center my-6"
		>
			<!-- Header menu links and small screen hamburger menu -->
			<div class="flex justify-between items-center px-4 sm:px-0">
				<!-- Header logos -->
				<div class="justify-start items-center">
					<router-link to="/"
						class="inline-block"
						><img
							v-if="theme === 'light'"
							src="@/assets/images/logox_dark.png"
							class="w-36"
							alt="Dark Logo"
						/>
						<img
							v-else
							src="@/assets/images/logox_light.png"
							class="w-36"
							alt="Light Logo"
						/>
					</router-link>
					<theme-switcher
						:theme="theme"
						@themeChanged="updateTheme"
						class="ml-3 inline-block bg-ternary-light dark:bg-ternary-dark hover:bg-hover-light dark:hover:bg-hover-dark hover:shadow-sm px-2 py-1.5 rounded-lg"
					/>
				</div>

				<!-- Theme switcher small screen -->
			

				<!-- Small screen hamburger menu -->
				<div class="sm:hidden">
					<button
						@click="isOpen = !isOpen"
						type="button"
						class="focus:outline-none"
						aria-label="Hamburger Menu"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							class="h-7 w-7 fill-current text-secondary-dark dark:text-ternary-light"
						>
							<path
								v-if="isOpen"
								fill-rule="evenodd"
								d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
								clip-rule="evenodd"
							></path>
							<path
								v-if="!isOpen"
								fill-rule="evenodd"
								d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
							></path>
						</svg>
					</button>
				</div>
			</div>

			<div 
				:class="isOpen ? 'block' : 'hidden'"
				class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-end items-end shadow-lg sm:shadow-none"
			>

				<span
					@click="go_to('/about')"
					class="font-general-medium block  text-lg font-medium text-primary-dark cursor-pointer dark:text-ternary-light hover:text-indigo-600 dark:hover:text-indigo-300  sm:mx-4 mx-2 sm:py-2 border-t-2 px-3 sm:px-2 sm:border-t-0 border-primary-light dark:border-secondary-dark "
					>个人介绍</span
				>
				<Button
					title="联系我们"
						class="font-general-medium bg-indigo-500 hover:bg-indigo-600 text-white shadow-sm rounded-md px-5 py-2 duration-300 "
					@click="showModal()"
					aria-label="Hire Me Button"
				/>

				
			</div>

		</div>
		<HireMeModal 
			:showModal="showModal"
			:modal="modal"
		/>
	</nav>
</template>

<style scoped></style>
