// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [

	{
		id: 1,
		title: '历史年轮',
		tag: 'history',
		category: '用动态年龄和向量关系看历史。把古人代入到他的时代背景中一年一年动态的感受他',
		img: require('@/assets/images/haibao_history.jpg'),
	},
	{
		id: 2,
		title: '向量单词',
		tag: 'worden',
		category: '回归朴素的词频、分类、排除法背单词，力图把它们做透，重视全局感和事物相关性',
		img: require('@/assets/images/haibao_worden.jpg'),
	},
];

export default projects;
