<script>
export default {
	props: ['comment'],
};
</script>

<template>
	<div class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark  px-3 py-3">
		<div class="text-left font-general-medium text-lg text-gray-400  mb-4">
			{{ comment.category }}
		</div>
		<div class="flex items-start justify-start">
			<img
				:src="require('@/assets/images/logo/' + comment.tag +'.png')"
				class="rounded border-none w-7 h-7 inline-block mr-2"/>
			<span class="text-lg text-gray-400  inline-block">{{ comment.nickname }}</span>
	
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
