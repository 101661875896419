<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			projectName: '',
			author: '道听途想网络科技',
		};
	},
};
</script>

<template>
	<div class="flex justify-center items-center text-center">
		<div
			class="font-general-regular text-sm text-gray-400 "
		>
			&copy; {{ copyrightDate }}    {{ author }}  
			<a
				href="https://beian.miit.gov.cn/"
				target="__blank"
				class="font-general-regular text-sm text-gray-400  hover:underline  duration-500"
				>   鲁ICP备20026180号-9  </a
			>
			
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
