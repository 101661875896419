<script>
import feather from 'feather-icons';
import Button from '../reusable/Button.vue';
import HireMeModal from '../HireMeModal.vue';

export default {
	components: {
		HireMeModal,
		Button,
	},
	data() {
		return {
			isOpen: false,
			modal: false,
			theme: ''
		};
	},

	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	methods: {
		updateTheme(theme) {
			this.theme = theme;
		},
		go_to(pageStr) {
			this.isOpen = false;
			this.$router.push(pageStr);
		},
		showModal() {
			this.isOpen = false;
			// 
			if (this.modal) {
				document
					.getElementsByTagName('html')[0]
					.classList.remove('overflow-y-hidden');
				this.modal = false;
			} else {
				console.log(338)
				document
					.getElementsByTagName('html')[0]
					.classList.add('overflow-y-hidden');
				this.modal = true;
			}
		},
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<nav class="sm:container sm:mx-auto">
		<!-- Header start -->
		<div
			class="z-10 max-w-screen-lg xl:max-w-screen-xl block items-center my-6"
		>
		<Button
					title="联系我们"
						class="font-general-medium bg-indigo-500 hover:bg-indigo-600 text-white shadow-sm rounded-md px-5 py-2 duration-300 "
					@click="showModal()"
					aria-label="Hire Me Button"
				/>
		</div>
		<HireMeModal 
			:showModal="showModal"
			:modal="modal"
		/>
	</nav>
</template>

<style scoped></style>
